<script>
import { mapActions } from 'vuex';
import config from '@config';
import TableView from '@components/elements/TableView';

export default {
    page: {
        title: 'Doctor Events'
    },

    extends: TableView,

    data() {
        return {
            pageTitle: 'Doctor Events'
        };
    },

    computed: {
        labels() {
            return [
                { value: 'image', width: '200px' },
                { value: 'title', width: '180px' },
                {
                    value: 'date',
                    width: '180px',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    text: 'Attending Users',
                    value: 'currently_attending',
                    width: '180px'
                },
                {
                    value: 'max_attending',
                    width: '180px'
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                }
            ];
        },

        includeDeleteAction() {
            return false;
        },

        options() {
            return {
                addButton: false,
                searchInput: false,
                pagination: false,
                clickableRows: false,
                sort: false,
                filters: false
            };
        }
    },

    async created() {
        const events = await this.getUserEvents(this.$route.params.doctorId);

        this.items = events;
    },

    methods: {
        ...mapActions({
            getUserEvents: 'doctors/getUserEvents'
        }),

        onRowClick(event) {
            this.$router.push(`/events/${event.id}`);
        },

        getImagePath(image) {
            return `${config.mediaUrl}/images/events/${image.date}/${image.id}.${image.extension}`;
        }
    }
};
</script>
